'use client'
import React, { useEffect, useState } from 'react'
import PlanCard from '@jobmojito/app/(application)/billing/components/TopupBlock/PlanCard'
import CheckList from '@jobmojito/app/(application)/billing/components/TopupBlock/CheckList'
import { getStipePlansPublic } from '@jobmojito/app/(application)/billing/utils/getStipePlansPublic'
import { PriceResponse, PriceType } from '@jobmojito/app/(application)/billing/utils/getStipePlans'
import {useTranslations} from 'next-intl';
import PlatformLanguagesIcon from '@jobmojito/app/utils/data/platform-languages-icon-circle'

import { languagesRaw ,findLanguage } from '@jobmojito/app/utils/data/platform-languages'

export default function TopupBlockPublic( { section, merchant_id, ignoreIncluded=false, geo='' } : { section: string, merchant_id:string, ignoreIncluded?:boolean, geo?:string }
  ) {
  const [prices, setPrices] = useState<PriceResponse | undefined>()
  const t = useTranslations('component-topup');

  useEffect(() => {
    getStipePlansPublic({ section, merchant_id, geo })
    .then ((x)=> {
      setPrices(x.data)
    })
  }, [])

  return (
    <>
    {prices?.discount && 
      <div className='mb-5 bg-success-50 border-1 rounded-xl p-2 border-success text-black whitespace-wrap text-wrap'>
        <PlatformLanguagesIcon code={(languagesRaw.find((lang)=>(lang.parity_code===prices?.discount_country))?.code)} className='inline w-4' /> &nbsp;
        {(languagesRaw.find((lang)=>(lang.parity_code===prices?.discount_country))? languagesRaw.find((lang)=>(lang.parity_code===prices?.discount_country))?.parity_text : t('Special discount for YOU automatically applied'))} &nbsp;
        <span className='font-bold'>-{(prices?.discount)*100}%</span>
      </div>
    } 
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-5">
      {prices?.prices?.map((item: PriceType, index: number) => {
        const {
          name,
          description,
          price,
          recommended,
          lifetime,
          features,
          image_url,
          id,
          currency,
          competition,
        } = item

        return (
          <div key={index}>
            <PlanCard
              title={t(name)}
              description={t(description)}
              price={price}
              image={image_url}
              lifetime={lifetime}
              mostPopular={recommended}
              id={id}
              className='mt-0'
              showPayButton={false}
              showStartButton={true}
              competition={(competition!==undefined && competition!==null && competition!=='')?t(competition):''}
              discount={prices?.discount}
              currency={currency}
              discountCode={prices?.discount_code}
            >
              {/*
              {features && (
                <BlockTitle
                  title={'This includes:'}
                  className={'text-gray-600 my-3'}
                  size={'sm'}
                />
              )} */}
              {features && (
                <div
                  className={'mt-5'}
                />
              )}
              {features &&
                features.length > 0 &&
                features.map((feature: string, index: number) => (
                  <div key={index}>
                    {(feature.includes('Included:')||feature.includes('Access to:'))?(!ignoreIncluded&&<CheckList>{t(feature)}</CheckList>):<CheckList>{t(feature)}</CheckList>}
                  </div>
                ))}
            </PlanCard>
          </div>
        )
      })}
    </div>
    </>
  )
}
