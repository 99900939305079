'use client'
import React, { useState } from 'react'
import { Input, Button, Divider, Radio, RadioGroup, Chip } from '@nextui-org/react'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { Database } from '@jobmojito/config/types/supabase'
import { updateTemporaryEmail } from '@jobmojito/app/utils/updateTemporaryEmail'
import { getUser } from '@jobmojito/app/utils/auth/getUser'
import * as Sentry from "@sentry/browser";

export default function EmailInput({
  className,
  title,
  onUpdateSuccess,
}: {
  className?: string
  title?: string
  onUpdateSuccess?: () => void
}) {
  
  const [email, setEmail] = useState('')
  const [type, setType] = useState('')
  const [processingError, setProcessingError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isEmailExists, setIsEmailExists] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isTypeValid, setIsTypeValid] = useState(true)

  const supabase = getClientSupabase()

  const handleChange = (event: { target: { value: string } }) => {
    const newEmail = event.target.value
    setEmail(newEmail)
  }

  const handleChangeType = (event: { target: { value: string } }) => {
    const newType = event.target.value
    setType(newType)
  }


  const validateEmail = (email: string) => {
    // Simple email validity check
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  async function emailConfirm() {
    setIsLoading(true)
    if (validateEmail(email) && (type!=='')) {
      // email validation is successful
      setIsValidEmail(true)
      setIsTypeValid(true);

      const user = await getUser(supabase)

      // call update email server function
      if (user?.id) {
        updateTemporaryEmail({
          email,
          userId: user.id,
          expectedJobLevel: type
        }).then(({ data, error, exception })=>{
          if (error) {
              if (exception) Sentry.captureException(exception);
              if (error === 'email exists') { 
                setIsEmailExists(true);
                setProcessingError('');
              } else {
                setIsEmailExists(false);
                setProcessingError(error);
              }
            setIsLoading(false)
          } else {
            // update email success
            if (data) {
              // refresh session for the updated user
              supabase.auth.refreshSession().then(({ data: sessionData, error: sessionError })=> {
                if (sessionError) {
                  console.error('sessionError', sessionError)
                }
                if (sessionData) {
                  window.location.reload()
                }
                // stop loading
                setIsLoading(false)
                // call onUpdateSuccess function if provided
                if (onUpdateSuccess) {
                  onUpdateSuccess()
                }
              })
            }  
          }
        })
      }
    } else {
      // email validation failed
      // show error message
      setIsValidEmail(validateEmail(email));
      setIsTypeValid((type!==''))
      setIsEmailExists(false);
      setProcessingError('');

      setIsLoading(false)
    }
  }

  return (
    <div className={className}>
      <div className='flex flex-col gap-5'>
        {title && <div className="text-black">{title}</div>}
        {isEmailExists&&<div className='text-danger mt-2 mb-1'>This email already exists. Please <a href="/login" className='underline font-bold'>login</a> and upload your resume again.</div>}
        {processingError!==''&&<div className='text-danger mt-2 mb-1'>{processingError}</div> }
        {!isValidEmail && (
          <div className="text-danger">
            Please enter a valid email address.
          </div>
        )}
        <Input
          label={'Enter your email'}
          type={'email'}
          defaultValue={email}
          name={'email'}
          onChange={handleChange}
          isRequired
          radius="sm"
          isInvalid={!isValidEmail}
        />
        <div className={'flex flex-row flex-wrap gap-4 p-4 rounded-xl '+(!isTypeValid?'bg-danger-50':'bg-default-100')}>
          <RadioGroup
            label="Your Seniority level"
            color="primary"
            className='w-full text-black'
            isRequired
            size='sm'
            name='expected_job_level'
            onChange={handleChangeType}
          >
            <div className={`flex flex-row gap-2 items-center`}>
                <div className={'text-xs w-32'}><Chip color="primary" variant="bordered" size='sm'>Just starting</Chip></div>
                <div className={`flex flex-col gap-2`}>
                  <Radio value="Intership" size='sm'>Intership</Radio>
                  <Radio value="Seasonal" size='sm'>Seasonal</Radio>
                </div>
            </div>
            <Divider className='bg-white h-1'/>
            <div className={`flex flex-row gap-2 items-center`}>
                <div className={'text-xs w-32'}><Chip color="primary" variant="bordered" size='sm'>Employee</Chip></div>
                <div className={`flex flex-col gap-2`}>
                  <Radio value="Entry-level" size='sm'>Entry-level</Radio>
                  <Radio value="Mid-level" size='sm'>Mid-level</Radio>
                  <Radio value="Senior-level" size='sm'>Senior-level</Radio>
                </div>
            </div>
            <Divider className='bg-white h-1'/>
            <div className={`flex flex-row gap-2 items-center`}>
                <div className={'text-xs w-32'}><Chip color="primary" variant="bordered" size='sm'>Manager</Chip></div>
                <div className={`flex flex-col gap-2`}>
                  <Radio value="Manager" size='sm'>Manager</Radio>
                  <Radio value="Director/Executive" size='sm'>Director/Executive</Radio>
                </div>
            </div>
            </RadioGroup>
        </div>
        <Button
          isLoading={isLoading}
          onPress={() => emailConfirm()}
          color="primary"
        >
          Confirm Email
        </Button>
      </div>
    </div>
  )
}
