'use client'
import useCookieConsent from '@jobmojito/components/cookies/useCookieConsent'
import pick from 'lodash/pick'
import { NextIntlClientProvider } from 'next-intl'
import React from 'react'

export function CookieConsentWrapper({
  children,
  isUseLocale = true,
}: {
  isUseLocale?: boolean
  children: React.ReactNode
}) {
  useCookieConsent(isUseLocale)

  return <>{children}</>
}
export default function CookieClientSideWrapper({
  children,
  locale,
  messages,
}: {
  children: React.ReactNode
  locale: string
  messages: any
}) {
  // const messages = useMessages()
  return (
    <NextIntlClientProvider
      timeZone='Europe/London'
      locale={locale}
      messages={pick(messages, 'component-cookie-consent')}
      // messages={messages}
    >
      <CookieConsentWrapper>{children}</CookieConsentWrapper>
    </NextIntlClientProvider>
  )
}
