'use client'
import { Database } from '@jobmojito/config/types/supabase'
import React, { useState } from 'react'
import BlockTitle from '@jobmojito/components/core/BlockTitle'
import { Button } from '@nextui-org/button'
import { Chip, Popover, PopoverContent, PopoverTrigger, Image, Link } from '@nextui-org/react'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { useError } from '@jobmojito/components/providers/ErrorProvider'
import EmailUpdateModal from '@jobmojito/app/(application)/resume/components/EmailUpdateModal'
import { getUser } from '@jobmojito/app/utils/auth/getUser'
import isTemporaryEmail from '@jobmojito/app/utils/isTemporaryEmail'
import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError } from "@supabase/supabase-js";
import {useTranslations} from 'next-intl';

export default function PlanCard({
  title,
  description = '',
  children,
  price,
  image,
  className = '',
  currency = 'eur',
  mostPopular = false,
  lifetime = false,
  showPayButton = true,
  showStartButton = false,
  id,
  discount = 0,
  competition = '',
  discountCode =''
}: {
  title: string
  description?: string
  children?: any | undefined
  price: string | number | boolean | typeof NaN
  image: string
  currency?: string | null | undefined
  className?: string
  mostPopular?: boolean
  lifetime?: boolean
  showPayButton?: boolean
  showStartButton?: boolean
  id: string
  discount?:number | null
  competition?: string
  discountCode?: string | null
}) {
  const { showError } = useError()
  const [stripePortalLoading, setStripePortalLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const t = useTranslations('component-topup');

  async function redirectToStripe(id: string) {
    const supabase = getClientSupabase()
    setStripePortalLoading(true)
    let user = null
    try {
      user = await getUser(supabase)
    } catch (error) {
      // no user
      console.error(error, 'getUser error')
    }

    if (isTemporaryEmail(user?.email)) {
      setIsShowModal(true)
      setStripePortalLoading(false)
    } else {
      setStripePortalLoading(true)
      const supabase = getClientSupabase()
      supabase.functions
        .invoke('stripe-checkout', {
          body: { price_id: id, merchant_payment: false, discount_code: discountCode },
          method: 'POST',
        })
        .then(({data, error}) => {
          setStripePortalLoading(false);

          if (error instanceof FunctionsHttpError) {
            error.context.json().then((errorMessage:{error:string})=>{
              showError(errorMessage?.error);
              console.error('Function returned an error:', errorMessage?.error)
            });
          } else if (error instanceof FunctionsRelayError) {
            showError(error?.message)
            console.error('Function returned an error:', error?.message)
          } else if (error instanceof FunctionsFetchError) {
            showError(error?.message)
            console.error('Function returned an error:', error?.message)
          } else {
        
            // all went well! There was a success
            if (data && data.url) {
              window.location.href = data.url
            }
          }
        })
    }
  }

  const newPrice =
    Number(price) !== Math.round(Number(price))
      ? Number(price).toFixed(2)
      : price
  const curr = (currency==='eur'?'€':(currency==='usd'?'$':'€'))

  return (
    <>
      <div
        className={
          'p-5 flex flex-col gap-2 relative text-left w-full rounded-xl ' +
          (mostPopular ? 'bg-black text-white' : 'bg-white text-black') +
          ' ' +
          className
        }
      >
        <div className={''}>
          <div className={'absolute rotate-12 z-30'} style={{ right: 20 }}>
            <Popover placement="bottom" offset={20} showArrow>
              <PopoverTrigger>
                <Chip aria-roledescription='button'
                  className={(lifetime ? ' cursor-pointer' : 'hidden')+' '+ (mostPopular?'bg-white text-black':'bg-primary text-white')}
                >
                  {t('Lifetime')}
                </Chip>
              </PopoverTrigger>
              <PopoverContent>
                <div className="px-1 py-2 flex flex-col gap-1">
                  <div className="text-small font-bold">
                    {t('Enjoy the freedom of our Lifetime Service')}
                  </div>
                  <div className="text-tiny">
                    {t('Your credits come with no expiration date, so feel free to use them at your own pace')}
                  </div>
                  <div className="text-tiny">
                    {t('Please note, credit usage is subject to the limits outlined on this page')}
                  </div>
                  <div className="text-small font-bold">
                    {t('Invest once, benefit forever')}
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className={'mb-5 z-20'}>
            {image && (
              <Image
                className={
                  'pointer-events-none aspect-video object-cover z-20 '
                }
                alt={title + ' Image'}
                width={160}
                height={90}
                radius={'sm'}
                src={image}
                style={{}}
              />
            )}
          </div>

          <BlockTitle
            size={'sm'}
            title={title}
            description={description}
            className={'mb-5'}
          />
            <div className='flex flex-row gap-3 mb-5 items-end'>
          {(discount!==0 && discount!==null && newPrice!==undefined && !Number.isNaN(newPrice) && !isNaN(Number(newPrice))) ?
            <>
            <BlockTitle
              size={'lg'}
              title={
                Number.isNaN(newPrice) ||
                isNaN(Number(newPrice)) ||
                newPrice === undefined
                  ? t('Free')
                  : curr + (Math.round((newPrice as number-(newPrice as number*discount)) * 100) / 100)
              }
            />
            <BlockTitle
              size={'sm'}
              strike={true}
              title={
                Number.isNaN(newPrice) ||
                isNaN(Number(newPrice)) ||
                newPrice === undefined
                  ? t('Free')
                  : curr + newPrice
              }
            />
            </>
          :
          <BlockTitle
            size={'lg'}
            title={
              Number.isNaN(newPrice) ||
              isNaN(Number(newPrice)) ||
              newPrice === undefined
                ? t('Free')
                : curr + newPrice
            }
          />
          }
          </div>
          { competition && <div className='text-xs mb-5 font-bold'>{competition}</div> }

          {showPayButton && (
            <Button
              className={'border-1 w-full '}
              color={'primary'}
              size={'sm'}
              radius={'sm'}
              onPress={() => redirectToStripe(id)}
              isLoading={stripePortalLoading}
            >
              {t('Get')}
            </Button>
          )}

          {showStartButton && (
            <Button
              className={'border-1 w-full '+(mostPopular?'bg-white text-black':'bg-black')}
              color={'primary'}
              href="/home"
              size={'sm'}
              as={Link}
              radius={'sm'}
            >
              {t('Start now')}
            </Button>
          )}

          <div className={'text-xs'}>{children}</div>
        </div>
      </div>
      {isShowModal && (
        <EmailUpdateModal
          isOpenCondition={true}
          onClose={() => setIsShowModal(false)}
        />
      )}
    </>
  )
}
